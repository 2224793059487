@tailwind base;

button:focus {
  @apply outline-none;
}

button:focus-visible {
  @apply ring;
}

button:disabled {
  @apply opacity-70 cursor-not-allowed;
}

@tailwind components;

@tailwind utilities;
